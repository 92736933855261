*:focus {
	border-color: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}
footer {
	height: 100px;
	font-size: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #212121;
	color: #ffffff;
}
